import { SubLevelLocation } from './Address';
import DeliveryPlan from './DeliveryPlan';
import { ShipmentStatusCodeType } from './ShipmentStatus';
import { Shipping } from './ShippingGroup';

export interface ShipmentDetailData {
  id: number;
  courierTrackingID: string;
  status: string;
  courier: Courier;
  deliveryPlan: DeliveryPlan;
  weightDimension: WeightDimension;
  labelUrl: string;
  fee: number;
  pickupPoint: PickupPoint;
  pickupTime: string;
  pickupInstruction: string;
  cartId: string;
  buyerInfo: BuyerInfo;
  items: Item[];
  pickupStartTime?: string;
  pickupEndTime?: string;
}

export interface BuyerInfo {
  name: string;
  recipientName?: string;
  email: string;
  contactNumber: string;
  countryCode: string;
  postalCode: string;
  city: string;
  state: string;
  addressLine1: string;
  addressLine2: string;
  unitNumber: string;
  subLevelLocations?: SubLevelLocation[];
}

export interface Courier {
  id: number;
  name: string;
  country_id: number;
  deactivate: boolean;
  code: string;
}

export interface Item {
  code: string;
  description: string;
  quantity: number;
}

export interface PickupPoint {
  id: number;
  lastName: string;
  firstName: string;
  email: string;
  phoneNumber: string;
  pickupTime: null;
  pickupInstruction: string;
  address: Address;
  default: boolean;
  deactivate: boolean;
}

export interface Address {
  id: number;
  address1: string;
  address2: string;
  postalCode: string;
  unitNumber: string;
  countryId: number;
  subLevelLocations: null;
  deactivate: boolean;
}

export interface WeightDimension {
  weight: number;
  width: number;
  length: number;
  height: number;
}

export interface ShipmentStatusHistory {
  id: string;
  courierTrackingID: string;
  statusHistory: ShipmentHistoryItem[];
}

export interface ShipmentHistoryItem {
  status: ShipmentStatusType;
  courierStatus: ShipmentStatusCodeType;
  stateText?: string;
  createdAt: string;
  stateDate?: string;
}

export enum ShipmentStatusType {
  FULFILLMENT_REQUEST_SENT = 'fulfillment_request_sent',
  COURIER_STAFF_ASSIGNED = 'courier_staff_assigned',
  PICKED_UP = 'picked_up',
  ON_DELIVERY = 'on_delivery',
  DELIVERED = 'delivered',
  FAILED_DELIVERY = 'failed_delivery',
  CANCELLED = 'cancelled',
}

export enum ShipmentName {
  FLASH_EXPRESS = 'flash_express',
  J_AND_T = 'jnt',
  NINJA_VAN = 'ninja_van',
  J_AND_T_SG = 'jnt_sg',
  J_AND_T_MY = 'jnt_my',
  FLASH_EXPRESS_PH = 'flash_express_ph',
  KERRY_EXPRESS_TH = 'kerry_express_th',
  GHTK_VN = 'ghtk',
}

export interface FailShipmentInfo {
  index?: string;
  message: string;
  code: CreateShipmentError;
  cartId: string;
  detail: { pickupPoint: PickupPoint; receiverInfo: BuyerInfo };
}

export enum CreateShipmentError {
  ErrorCodeInternalServer = 1000,
  ErrorCodeCourierNotSupport = 1001,
  ErrorCodeCourierAuthenticationInvalid = 1010,
  ErrorCodeDeliveryAndPickupAddressInvalid = 1022,
  ErrorCodeRecipientAddressInvalid = 1020,
  ErrorCodePickupAddressInvalid = 1021,
  ErrorCodeDimensionInvalid = 1030,
  ErrorCodeDeliveryPlanInvalid = 1031,
  ErrorCodeOrderExists = 1040,
  ErrorCodeOther = 1050,
}

export interface CreateShipmentResponse {
  successes: Shipping[];
  fails: FailShipmentInfo[];
}

export interface ShipmentMassWayBill {
  url: string;
  error: string;
  failedShipments: FailedShipmentWayBill[];
}

export interface FailedShipmentWayBill {
  id: string;
  error: string;
}

export enum ShipmentActionsStatus {
  DOWNLOAD_INVOICE = 'download_invoice',
  VIEW_NOTE = 'view_note',
  VIEW_REASON = 'view_reason',
  PRINT_WAYBILL = 'print_waybill',
  VIEW_SHIPPING_DETAILS = 'view_shipping_details',
  CANCEL_SHIPPING = 'cancel_shipping',
  MOVE_TO_ARCHIVE = 'move_to_archive',
}
