export enum MessageType {
  DefaultCheckoutMessage = 0,
  CheckoutMessageWithCartExpiry = 1,
  CartExpired = 2,
  OutOfStock = 3,
  PaymentReminder = 4,
  ProductDeactivation = 5,
  CartPendingForVerification = 6,
  CartRejected = 7,
  OrderPaid = 8,
  OrderShipping = 9,
  OrderDelivered = 10,
  OrderPacking = 11,
  Welcome = 12,
  OrderDeliveryFailed = 13,
}

export enum MessageGroupType {
  Checkout,
  OutOfStock,
  Notification,
  CheckoutWithButton,
  ProductDeactivated,
}

export type Message = {
  id: string;
  type: MessageGroupType;
  messageType: MessageType;
  value: string;
  checkoutText: string;
};

export enum TemplateMessagesUrlType {
  Welcome = 'welcome',
  AddProductToCart = 'add_product_to_cart',
  ProductAdded = 'product_added',
  ProductDeactivated = 'product_deactivated',
  OutOfStock = 'out_of_stock',
  CheckoutReminder = 'checkout_reminder',
  OrderStatusUpdate = 'order_status_update',
  PendingVerification = 'pending_verification',
  PaymentSuccessful = 'payment_successful',
  PaymentFailed = 'payment_failed',
  Shipping = 'shipping',
  Delivered = 'delivered',
  DeliveryFailed = 'delivery_failed',
  Notification = 'notification',
}

export enum ReplyCommentType {
  ValidSellingCode = 14,
  AllComment,
  SoldOut,
  CongratsFirstBidder,
}

export enum ReplyCommentUrlType {
  AllComment = 'all_comments',
  ValidSellingCode = 'successfully_added_to_cart',
  SoldOut = 'sold_out',
  CongratsFirstBidder = 'congrats_first_bidder',
}
